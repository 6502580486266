<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.tea_sell') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <!-- fiscal year -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="fiscal_year_id"
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- sale no -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Sale No" vid="serial_no" rules="required">
                    <b-form-group label-for="serial_no" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGarden.sale_no') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="serial_no"
                        v-model="formData.serial_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- sale date -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Sale Date" vid="sale_date" rules="required">
                    <b-form-group
                      class="row"
                      label-for="sale_date"
                      slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGarden.sale_date') }}  <span class="text-danger">*</span>
                      </template>
                      <date-picker
                        id="sale_date"
                        v-model="formData.sale_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :class="errors[0] ? 'is-invalid' : ''"
                        :locale="currentLocale"
                      >
                      </date-picker>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- sale type -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Sale Type" vid="sale_type" rules="required">
                    <b-form-group
                        slot-scope="{ valid, errors }"
                        label-cols-sm="12"
                        label-for="sale_type"
                        :label="$t('teaGarden.sale_type')">
                        <b-form-radio-group
                            :state="errors[0] ? false : (valid ? true : null)"
                            class="custom-control-inline-wrapper mb-0" v-model="formData.sale_type" size="lg" :options="saleTypeList"
                            name="sale_type">
                        </b-form-radio-group>
                        <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- type -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Type" vid="type" rules="required">
                    <b-form-group
                        slot-scope="{ valid, errors }"
                        label-cols-sm="12"
                        label-for="type"
                        :label="$t('teaGarden.factory_type')">
                        <b-form-radio-group
                            :disabled="isFactoryAdminCheckFactoryId() || isGardenAdminCheckGardenId() ? true : false"
                            :state="errors[0] ? false : (valid ? true : null)"
                            class="custom-control-inline-wrapper mb-0" v-model="formData.type" size="lg" :options="factoryTypeList"
                            name="factory_type">
                        </b-form-radio-group>
                        <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- garden -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="formData.type === 1">
                  <ValidationProvider name="Garden" vid="garden_id" rules="required|min_value:1">
                    <b-form-group label-for="garden_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.garden_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        :disabled="isGardenAdminCheckGardenId() ? true : false"
                        plain
                        id="garden_id"
                        v-model="formData.garden_id"
                        :options="gardenlist"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- factory -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="formData.type === 2">
                  <ValidationProvider name="Factory" vid="factory_id" rules="required|min_value:1">
                    <b-form-group label-for="factory_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.factory_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        :disabled="isFactoryAdminCheckFactoryId() ? true : false"
                        plain
                        id="factory_id"
                        v-model="formData.factory_id"
                        :options="masterBoughtLeafFactoryList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- zone -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="formData.sale_type === 2">
                  <ValidationProvider name="Zone" vid="zone_id" rules="required|min_value:1">
                    <b-form-group label-for="zone_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.zone') }}  <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="zone_id"
                        v-model="formData.zone_id"
                        :options="zoneList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- broker -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="formData.sale_type === 2">
                  <ValidationProvider name="Broker" vid="broker_id" rules="required|min_value:1">
                    <b-form-group label-for="broker_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.broker_name') }}  <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="broker_id"
                        v-model="formData.broker_id"
                        :options="masterBrokerInfoList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <!-- add more section star here -->
              <div class="add-more-area border-top border-dark pt-3">
                <b-row>
                  <!-- tea type -->
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="tea_type_id" rules="">
                      <b-form-group label-for="tea_type_id" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.made_tea_type') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          id="tea_type_id"
                          v-model="formDataAddMore.tea_type_id"
                          :options="masterMadeTeaTypeList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- transfer qty -->
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="formData.sale_type === 2">
                    <ValidationProvider name="Transfer Quantity" vid="transfer_qty" rules="">
                      <b-form-group label-for="transfer_qty" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGarden.transfer_quantity') }} ({{ $t('globalTrans.kg') }}) <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          disabled
                          id="transfer_qty"
                          :value="tea_pro_current_stock_loading ? $t('globalTrans.loading') : tea_pro_current_stock"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keypress="isNumber"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- sale qty -->
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Stock Quantity" vid="sale_qty" rules="">
                      <b-form-group label-for="sale_qty" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGarden.sale_qty') }} ({{ $t('globalTrans.kg') }}) <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="sale_qty"
                          v-model="formDataAddMore.sale_qty"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="getTeaTypeWiseTotalAmount"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- avg price -->
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Avg Price" vid="avg_price" rules="">
                      <b-form-group label-for="avg_price" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGarden.avg_price') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="avg_price"
                          v-model="formDataAddMore.avg_price"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="getTeaTypeWiseTotalAmount"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- total price -->
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Total Price" vid="total_price" rules="">
                      <b-form-group label-for="total_price" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGarden.total_price') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          disabled
                          id="total_price"
                          v-model="formDataAddMore.total_price"
                          :state="errors[0] ? false : (valid ? true : null)"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- remarks -->
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Remarks" vid="remarks" rules="">
                      <b-form-group label-for="remarks" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('globalTrans.remarks') }}
                        </template>
                        <b-form-textarea
                          id="remarks"
                          v-model="formDataAddMore.remarks"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-textarea>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <b-button type="button" size="sm" class="btn btn-success" @click="addItem">
                      <template v-if="buttonLoading">{{ $t('globalTrans.loading') }}</template>
                      <template v-else>
                        <i class="ri-add-circle-fill"></i> {{ $t('globalTrans.add_more') }}
                      </template>
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple bordered hover small responseive class="mt-3">
                  <b-thead>
                    <b-tr>
                      <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                      <b-th>{{ $t('teaGardenConfig.made_tea_type') }}</b-th>
                      <b-th v-if="formData.sale_type === 2">{{ $t('teaGarden.transfer_quantity') }} ({{ $t('globalTrans.kg') }})</b-th>
                      <b-th>{{ $t('teaGarden.sale_qty') }} ({{ $t('globalTrans.kg') }})</b-th>
                      <b-th v-if="formData.sale_type === 2">{{ $t('teaGarden.sale_percentage') }}%</b-th>
                      <b-th>{{ $t('teaGarden.avg_price') }}</b-th>
                      <b-th>{{ $t('teaGarden.total_price') }}</b-th>
                      <b-th>{{ $t('globalTrans.remarks') }}</b-th>
                      <b-th>{{ $t('globalTrans.action') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="formData.details.length">
                      <b-tr v-for="(item, index) in formData.details" :key="index">
                        <b-td>{{ $n(index + 1) }}</b-td>
                        <b-td>{{ getTeaTypeName(item.tea_type_id) }}</b-td>
                        <b-td v-if="formData.sale_type === 2">
                          {{$n(item.transfer_qty)}}
                        </b-td>
                        <b-td>
                          {{$n(item.sale_qty)}}
                        </b-td>
                         <b-td v-if="formData.sale_type === 2">
                          {{$n(item.sale_percentage)}}
                        </b-td>
                        <b-td>
                          {{$n(item.avg_price)}}
                        </b-td>
                        <b-td>
                          {{$n(item.total_price)}}
                        </b-td>
                        <b-td>
                          <ValidationProvider name="Remarks" vid="remarks" rules="">
                            <b-form-group label-for="remarks" slot-scope="{ valid, errors }">
                              <b-form-textarea
                                id="remarks"
                                v-model="item.remarks"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-textarea>
                              <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-td>
                        <b-td>
                          <b-button type="button" size="sm" class="btn btn-danger" @click="deleteItem(index)">
                            <i class="ri-close-line"></i>
                          </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="9">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                    </template>
                    <b-tr>
                      <b-td :colspan="formData.sale_type === 2 ? 3 : 2"><strong>{{ $t('globalTrans.grand_total') }}</strong></b-td>
                      <b-td class="text-right"><strong>{{ $n(getTotalQty()) }}</strong></b-td>
                      <b-td colspan="5"></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <b-row class="text-right mt-3">
                <b-col>
                    <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
    </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { teaSellStore, teaProBrokerCheckStock } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import BreadCumb from '@/components/BreadCumb.vue'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        factory_id: null,
        garden_id: null,
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        zone_id: null,
        broker_id: null,
        type: 2,
        sale_type: 2,
        sale_date: helpers.currentDate(),
        details: []
      },
      formDataAddMore: {
        tea_type_id: 0,
        sale_qty: 0,
        transfer_qty: 0,
        avg_price: 0,
        total_price: 0,
        sale_percentage: 0,
        remarks: ''
      },
      masterBrokerInfoList: [],
      buttonLoading: false,
      tea_pro_current_stock: 0,
      tea_pro_current_stock_loading: false
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    masterBoughtLeafFactoryList () {
      return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.status === 1)
    },
    masterMadeTeaTypeList () {
      return this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.filter(item => item.status === 1)
    },
    zoneList () {
      return this.$store.state.TeaGardenService.commonObj.zoneList
    },
    gardenlist () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    factoryTypeList () {
      return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Gardens Fatory' : 'বাগানের নিজস্ব কারখানা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Bought Leaf Factory' : 'বটলীফ কারখানা' }
      ]
    },
    saleTypeList () {
      return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Ex-Garden Sale' : 'এক্স-গার্ডেন চা বিক্রয়' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Broker Sale' : 'ব্রোকার চা বিক্রয়' }
      ]
    }
  },
  watch: {
    'formData.zone_id': function (newVal) {
      if (newVal) {
        this.masterBrokerInfoList = this.getBrokerList(newVal)
      } else {
        this.formData.zone_id = null
        this.masterBrokerInfoList = []
      }
    },
    'formData.type': function (newVal, oldVal) {
      if (newVal && newVal === 1) {
        this.formData.factory_id = null
      }
      if (newVal && newVal === 2) {
        this.formData.garden_id = null
      }
    },
    'formDataAddMore.tea_type_id': function (newVal, oldVal) {
      if (newVal && this.formData.sale_type === 2) {
        this.getTeaProBrokerCurrentStock()
      }
    },
    currentLocale: function () {
      if (this.formData.zone_id) {
        this.masterBrokerInfoList = this.getBrokerList(this.formData.zone_id)
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData(this.$route.query.id)
    }

    if (this.isGardenAdminCheckGardenId()) {
      const gardenObj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.isGardenAdminCheckGardenId())
      if (gardenObj.has_own_garden === 1) {
        this.formData.type = 1
      } else {
        this.formData.type = 2
      }
      this.formData.sale_type = 1
      this.formData.garden_id = this.isGardenAdminCheckGardenId()
    }
    if (this.isFactoryAdminCheckFactoryId()) {
        this.formData.type = 2
        this.formData.sale_type = 2
        this.formData.factory_id = this.isFactoryAdminCheckFactoryId()
    }
  },
  methods: {
    async saveUpdate () {
      if (!this.formData.details.length) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('teaGarden.select_required_item')
        })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const result = await RestApi.postData(teaGardenServiceBaseUrl, teaSellStore, this.formData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$router.push('tea-sale')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    back () {
      this.$router.go(-1)
    },
    getTeaTypeName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async addItem () {
      if (!(this.formDataAddMore.tea_type_id && this.formDataAddMore.sale_qty && this.formDataAddMore.avg_price)) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please select tea type and sale quantity'
        })
      }

      if (parseFloat(this.formDataAddMore.sale_qty) > parseFloat(this.formDataAddMore.transfer_qty) && this.formData.sale_type === 2) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Sale Quantity can not gether thank tranfer quantity'
        })
      }

      const obj = this.formData.details.find(item => item.tea_type_id === this.formDataAddMore.tea_type_id)
      if (obj !== undefined) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'This tea type already exists'
        })
      }
      this.buttonLoading = true
      this.buttonLoading = false
      this.formData.details.push(this.formDataAddMore)
      this.formDataAddMore = {
        tea_type_id: 0,
        sale_qty: 0,
        transfer_qty: 0,
        avg_price: 0,
        total_price: 0,
        sale_percentage: 0,
        remarks: ''
      }
    },
    deleteItem (index) {
      this.formData.details.splice(index, 1)
    },
    getTotalQty () {
      let totalQty = 0
      this.formData.details.forEach(item => {
        totalQty += parseFloat(item.sale_qty)
      })
      return totalQty
    },
    getBrokerList (id = null) {
      let list = this.$store.state.TeaGardenService.commonObj.masterBrokerInfoList.filter(item => item.status === 1)
      if (id) {
        list = list.filter(item => item.zone_id === parseInt(id))
      }
      return list
    },
    getTeaTypeWiseTotalAmount () {
      if (this.formDataAddMore.sale_qty && this.formDataAddMore.avg_price) {
        const totalPrice = parseFloat(this.formDataAddMore.sale_qty) * parseFloat(this.formDataAddMore.avg_price)
        this.formDataAddMore.total_price = totalPrice.toFixed(2)
        if (this.formDataAddMore.transfer_qty) {
          const salePercentage = (parseFloat(this.formDataAddMore.sale_qty) / parseFloat(this.formDataAddMore.transfer_qty)) * 100
          this.formDataAddMore.sale_percentage = salePercentage.toFixed(2)
        }
        return totalPrice
      }
      return 0
    },
    async getTeaProBrokerCurrentStock () {
        this.tea_pro_current_stock_loading = true
        let result = null
        const data = {
          garden_id: this.formData.garden_id,
          factory_id: this.formData.factory_id,
          type: this.formData.type,
          broker_id: this.formData.broker_id,
          tea_type_id: this.formDataAddMore.tea_type_id
        }
        result = await RestApi.getData(teaGardenServiceBaseUrl, teaProBrokerCheckStock, data)
        if (result.success) {
          this.tea_pro_current_stock = result.data.stock_qty
          this.formDataAddMore.transfer_qty = result.data.stock_qty
        } else {
          this.formDataAddMore.transfer_qty = 0
          this.tea_pro_current_stock = 0
        }
        this.tea_pro_current_stock_loading = false
    }
  }
}
</script>
